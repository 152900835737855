'use strict';

//ellipsisFilter
/* @ngInject */
export default class EllipsisFilter {
  static filter(text, fixedLength) {
    if (angular.isUndefined(text)) {
      return text;
    }

    if (text.length > fixedLength) {
      var trimmed = text.substring(0, fixedLength) + 'â¦';
      return trimmed;
    }

    return text;
  }
}
