'use strict';

/* @ngInject */
import * as angular from "angular";
import {IScope, IRootScopeService, ILocationService, ILogService} from "angular";
import RestService from "../../../services/rest.service";
import {EAPagerFieldMode, ProvisionField, SinglePersonResponse} from "../../../data/person.data";

require('./external.persons.view.component.scss')
export default class ExternalPersonsController {
  public $log: ILogService;
  public $rootScope: IRootScopeService;
  public $scope: IScope;
  public $location:ILocationService;
  public restService: RestService;
  public errorService;
  public Notification;
  public id:string;
  public auth:string;
  public username:string;
  public singlePerson: SinglePersonResponse;
  public isLoading:boolean= true;
  public isError:boolean= false;
  public lstOfFields:string[];
  public provField: typeof ProvisionField = ProvisionField;
  public aPagerMode: typeof EAPagerFieldMode = EAPagerFieldMode;
  public isMobilValid:boolean= false;
  public isEMailValid:boolean= false;
  public isValidAPagerProValue:boolean= false;
  public modeSwitchAllowed:boolean=true;

  constructor($rootScope: IRootScopeService, $scope:IScope, $log:ILogService, $location:ILocationService, restService:RestService, errorService, Notification) {
    this.$log = $log;
    this.$log.debug('ExternalPersonsController started...');
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$location = $location;
    this.restService = restService;
    this.errorService = errorService;
    this.Notification = Notification;


    //Load person on startup
    this.loadPerson();
  }

  getKeyTanslation(){
    if (!this.singlePerson || !this.singlePerson.person|| !this.singlePerson.person.apagerSettings){
      return ' ';
    }
    const field = this.singlePerson.person.apagerSettings.keyOfProvisioningField;
    if (field === ProvisionField.EMAIL){
      return "E-Mail";
    }
    if (field === ProvisionField.MOBIL){
      return "Telefonnummer";
    }
    return ' ';
  }


  loadPerson() {
    this.isLoading = true;

    this.id = this.$location.search().id;
    this.auth = this.$location.search().auth;
    this.username = this.$location.search().username;

    if (angular.isUndefined(this.id) || angular.isUndefined(this.auth) || angular.isUndefined(this.username)) {
      //Invalid values
      this.errorService.notify(this.errorService.ERROR_IDS.INVALID_ARGUMENTS);
      return;
    }

    this.restService.loadPerson(this.username, this.id, this.auth,
      (response) => {
        this.singlePerson = response.data;
        this.lstOfFields = response.data.lstOfFields;
        this.setDataOfSettingsForOldValues();
        this.modeSwitchAllowed= !(this.singlePerson.person.apagerSettings.apagerFieldMode===EAPagerFieldMode.TOKEN && this.singlePerson.person.apagerSettings.apagerProValue);
        this.isLoading = false;
        this.checkMode()
        this.$scope.$applyAsync();
      },
      () => {
        //Error occured
        this.isLoading = false;
        this.isError = true;
        this.$scope.$applyAsync();
      });
  };

  isOneFieldValid(){
    if (angular.isUndefined(this.singlePerson)) return false;
    if (angular.isUndefined(this.singlePerson.person))return false;
    if (angular.isUndefined(this.singlePerson.person.data))return false;
    this.isMobilValid= this.validatePhoneFormat(this.singlePerson.person.data.mobil, true);
    this.isEMailValid = this.validateEMailFormat(this.singlePerson.person.data.email, true);
    return this.isMobilValid&& this.isEMailValid;
  }
  public isProvField(provField:ProvisionField):boolean{
    if (!this.singlePerson ||!this.singlePerson.person.apagerSettings.keyOfProvisioningField){
      return false;
    }
    if (this.singlePerson.person.apagerSettings.apagerFieldMode===EAPagerFieldMode.TOKEN) {
      if (provField === this.singlePerson.person.apagerSettings.keyOfProvisioningField) {
        return true;
      }
    }
    return false;
  }

  checkMode(){
    if (!this.singlePerson||!this.singlePerson.person||!this.singlePerson.person.apagerSettings.apagerProValue){
      return;
    }
    if(this.modeSwitchAllowed){
      if (this.singlePerson.person.apagerSettings.apagerProValue.includes('@') || (this.singlePerson.person.apagerSettings.apagerProValue.match("^00[0-9]+$")&&this.singlePerson.person.apagerSettings.apagerProValue.length>8)){
        this.singlePerson.person.apagerSettings.apagerFieldMode= EAPagerFieldMode.LEGACY;
        this.singlePerson.person.apagerSettings.keyOfProvisioningField= undefined;
        // if a person was configured with a token before don't allow switch back to legacy
      }
      if(this.singlePerson.person.apagerSettings.apagerProValue.match(/^[A-Z0-9.]+$/)&& this.singlePerson.person.apagerSettings.apagerProValue.length===8){
        this.singlePerson.person.apagerSettings.apagerFieldMode=EAPagerFieldMode.TOKEN;
        this.singlePerson.person.apagerSettings.keyOfProvisioningField= ProvisionField.EMAIL;
      }
    }
    if (this.singlePerson.person.apagerSettings.apagerFieldMode===EAPagerFieldMode.TOKEN){
      this.validateToken(this.singlePerson.person.apagerSettings.apagerProValue);
      this.isMobilValid= this.validatePhoneFormat(this.singlePerson.person.data.mobil, true);
      this.isEMailValid = this.validateEMailFormat(this.singlePerson.person.data.email, true);
    }

    if (this.singlePerson.person.apagerSettings.apagerFieldMode===EAPagerFieldMode.LEGACY){
      this.isMobilValid= this.validatePhoneFormat(this.singlePerson.person.data.mobil, true);
      this.isEMailValid = this.validateEMailFormat(this.singlePerson.person.data.email, true);
      this.validateLegacyAPager(this.singlePerson.person.apagerSettings.apagerProValue);
    }
    this.$scope.$applyAsync();

  }

  validateEMailFormat(value: string,isProvFieldCheck:boolean):boolean {
    if (angular.isUndefined(value)||!value) {
      if (this.singlePerson.person.apagerSettings.keyOfProvisioningField && this.singlePerson.person.apagerSettings.keyOfProvisioningField === ProvisionField.EMAIL) {
        return false;
      }
      // don't display error on empty optional fields, in case of other modes
      if (isProvFieldCheck&&this.singlePerson.person.apagerSettings.keyOfProvisioningField !== ProvisionField.EMAIL) {
        return true;
      }
    }
    if (value.includes("@")) {
      // Regex from https://stackoverflow.com/a/46370978
      const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

      if (value.match(regexp)) {
        return true;
      }
      return false;
    }
  }
  private validateLegacyAPager(value: string) {
    // do not show warning on empty field
    if (angular.isUndefined(value) || value === '') {
      this.isValidAPagerProValue = true;
      return;
    }
    // undo if valid was true
    this.isValidAPagerProValue = false;
    // check on E-Mail first
    this.isValidAPagerProValue = this.validateEMailFormat(value,false);
    if (this.isValidAPagerProValue) {
      return;
    }
    // validate on phone number if previous validation failed
    this.isValidAPagerProValue = this.validatePhoneFormat(value,false);
  }
  valid

  validateToken(value:string){
    //this.valuesChanged();
    if (angular.isUndefined(value) || !value){
      this.isValidAPagerProValue = false;
      return;
    }
    if (value.length!== 8){
      this.isValidAPagerProValue = false;
      return;
    }
    // regex matching parallel to backend structure
    if (value.match(/^[a-zA-Z0-9.]+$/)){
      this.isValidAPagerProValue = true;
      return;
    }
    this.isValidAPagerProValue = false;
  }
  validatePhoneFormat(value: string, isProvFieldCheck:boolean): boolean {
    if (angular.isUndefined(value)||!value){
      if (this.singlePerson.person.apagerSettings.keyOfProvisioningField && this.singlePerson.person.apagerSettings.keyOfProvisioningField === ProvisionField.MOBIL) {
        return false;
      }
      // don't display error on empty optional fields, in case of other modes
      if (isProvFieldCheck&&this.singlePerson.person.apagerSettings.keyOfProvisioningField !== ProvisionField.MOBIL) {
        return true;
      }
    }
    if (!value.startsWith("00")) {
      return false;
    }
    // contains only digit values and starts with double zeros
    if (value.match("^00[0-9]+$")) {
      return true;
    }

    return false;
  }



  /**
    Save an existing person
  */
  savePerson() {
    this.isLoading = true;

    this.singlePerson.person.displayName = this.singlePerson.person.lastName + ' ' + this.singlePerson.person.firstName;

    this.restService.savePersonViaExternalAccess(this.username, this.auth, this.singlePerson,
      () => {
        this.isLoading = false;
        this.loadPerson();
        this.Notification.success({message: 'Daten erfolgreich aktualisiert!', title: 'Ãnderungen durchgefÃ¼hrt'});
      },
      () => {
        //Error occured
        this.isLoading = false;
      });
  };

  private setDataOfSettingsForOldValues() {
    if (!this.singlePerson.person.apagerSettings.apagerFieldMode){
      //an old entry is edited set mode to legacy
      this.singlePerson.person.apagerSettings.apagerFieldMode=EAPagerFieldMode.LEGACY;
    }
    if (this.singlePerson.person.apagerSettings.apagerFieldMode=== EAPagerFieldMode.TOKEN){
    if (!this.singlePerson.person.apagerSettings.keyOfProvisioningField){
      this.singlePerson.person.apagerSettings.keyOfProvisioningField= ProvisionField.EMAIL;
    }
    }

  }
}