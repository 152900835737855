export interface EventAccess {
  event: Event;
  persons: Array<AccessPerson>;
}

export interface EventFeedbackResponse {
  stats: EventFeedbackStatistics;
  feedbacks: EventFeedback[];
  min: number;
  minOK: boolean;
  max: number;
  maxOK: boolean;
}

export enum EEventFeedback {
  YES = 'YES', NO = 'NO', RESERVATION = 'RESERVATION', UNKNOWN = 'UNKNOWN'
}

export interface EventFeedbackStatistics {
  total: number;
  positive: number;
  negative: number;
  reservation: number;
}

export interface EventFeedback {
  id: string;
  name: string;
  deviceId: string;
  created: string;
  functions: string[];
  groups: string[];
  feedback: EEventFeedback;
}

export enum ESerialAppointmentWeekday {
  MONDAY='MONDAY',
  TUESDAY='TUESDAY',
  WEDNESDAY= "WEDNESDAY",
  THURSDAY= "THURSDAY",
  FRIDAY= "FRIDAY",
  SATURDAY="SATURDAY",
  SUNDAY="SUNDAY",
  NONE="NONE"

}

export interface Event {
  id: string;
  calendarId: string;
  calendarName: string;
  serialAppointmentId: string;
  serialAppointmentIteration: number;
  serialAppointmentDistance: number;
  serialAppointmentWeekday:ESerialAppointmentWeekday;
  title: string;
  body: string;
  color: string;
  startDate: string;
  endDate: string;
  latestPossibleFeedback: string;
  fullDay: boolean;
  hasNotifications: boolean;
  type: EEventType;
  status: EEventState;
  cancelledReason: string;
  location: string;
  responsiblePerson: string;
  firstNotification: EventNotification;
  secondNotification: EventNotification;
  maxParticipants: number;
  minParticipants: number;
  topic: string;
  withFeedback: boolean;
  fromIcalSync: boolean;
}

export enum EEventType {
  LESSON = 'LESSON', // Unterricht
  TRAINING = 'TRAINING', // Ãbung
  COURSE = 'COURSE', // Lehrgang
  EVENT = 'EVENT' //Termin
}

export enum EEventState {
  PLANNED = 'PLANNED',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED'
}

export interface EventNotification {
  duration: number;
  active: boolean;
  timeUnit: ETimeUnit;
}

export enum ETimeUnit {
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAYS = 'DAYS'
}

export interface AccessPerson {
  name: string;
  personId: string;
  alarmGroupId: string;
  alarmGroupColor: string;
}

export interface UpcomingEvents {
  count: number;
}